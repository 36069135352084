export const hotelBranchListGet = (workerData) => {
    let workerDataTemp = workerData.data
    let response = {}
    response["success"] = workerDataTemp.success
    response["total"] = workerDataTemp.total
    response["data"] = workerDataTemp.data
    return response
}

export const branchStatusUpdateApi = (data) => {
    return data.data;
}
export const blockRoomListGet = (data) => {
    return data.data;
}
export const hotelRoomBookingsPostCall = (data) => {
    return data.data;
}
export const branchCreatePost = (data) => {
    return data.data;
}
export const branchCreatePatch = (data) => {
    return data.data;
}
export const branchDeleteApi = (data) => {
    return data.data;
}
export const hotel_room_list_get = (data) => {
    return data.data;
}
export const roomRateOverridePost = (data) => {
    return data.data;
}
export const specialRatePost = (data) => {
    return data.data;
}
export const blockRoomPost = (data) => {
    return data.data;
}
export const subscriptionTypeGet = (data) => {
    return data.data;
}
export const subscriptionDetailsPost = (data) => {
    return data.data;
}
export const hotle_NotLiveBranch_listGet = (data) => {
    return data.data;
}
export const hotle_wise_RoomType_listGet = (data) => {
    return data.data;
}
export const roomType_wise_room_listGet = (data) => {
    return data.data;
}
export const branchImageSavePost = (data) => {
    return data.data;
}

// export const currentUser = (currentUserData) => {
//     let response = currentUserData.data;
//     //console.log("currentUser response ===============>", response)
//     let currentUserResData = {};
//     if (response) {
//         if (response.success) {
//             currentUserResData = {
//                 "success": response.success,
//                 "data": {
//                     "id": response.data.id,
//                     //"is_leave_validator": response.data.id == 1 ? true : response.data.is_leave_validator,
//                     //"is_trainer": response.data.is_trainer,
//                     "user_details": {
//                         "first_name": response.data.user_details.first_name,
//                         "last_name": response.data.user_details.last_name,
//                         "user_email": response.data.user_details.user_email,
//                         "active": response.data.user_details.active,
//                         "profile_img_url": response.data.user_details.profile_img,
//                         "role_id": response.data.user_details.role_id,
//                         "role_name": response.data.user_details.role_name.toLowerCase(),
//                         "user_type": response.data.user_details.user_type,
//                         "contact_number": response.data.user_details.contact_number,
//                         //"org_id": response.data.user_details.org_id,
//                         //"org_name": response.data.user_details.org_name,
//                         //"org_logo": response.data.user_details.org_logo,
//                         //"marketed_by_logo": response.data.user_details.marketed_by_logo,
//                         //"marketed_by_name": response.data.user_details.marketed_by_name,
//                         //"marketed_by_url": response.data.user_details.marketed_by_url,
//                         //"secret_key": response.data.user_details.secret_key,
//                         //"inbound_url": response.data.user_details.inbound_url,
//                         "role_permission": response.data.user_details.role_permission,
//                         "org_id": response.data.user_details.org_id,
//                         "org_name": response.data.user_details.org_name,
//                         "is_subscribed": response.data.user_details.is_subscribed,
//                         "version":response.data.user_details.version,
//                         // "version_description": response.data.user_details.version_description
//                     }
//                 }
//             }
//         } else {
//             currentUserResData = {
//                 "success": response.success,
//                 "message": response.message
//             }
//         }
//     }
//     return currentUserResData;
// }

export const paymentPriceDistributionGet = (data) => {
    return data.data;
}
export const paymentPost = (data) => {
    return data.data;
}
export const roomRateOverrideMonthlyCalculations_get = (data) => {
    return data.data;
}
export const hotelRoomTypesSearchListGet = (data) => {
    return data.data;
}
export const hotelBranchLocationGet = (data) => {
    return data.data;
}
export const customerListApiGet = (data) => {
    return data.data;
}
export const paymentDetailsApiGet = (data) => {
    return data.data;
}
export const guestCreateApi = (data) => {
    return data.data;
}
export const locationWiseBranchApiGet = (data) => {
    return data.data;
}
export const countryListGet = (data) => {
    return data.data;
}
export const stateListGet = (data) => {
    return data.data;
}
export const cityListGet = (data) => {
    return data.data;
}
export const emailCheckApiGet = (data) => {
    return data.data;
}


