import * as Store from '../store'
import LoginActionTypes from '../GlobalModules/Login/Utility/LoginActionTypes';
import AllActionTypes from './AllActionTypes'
import axios from 'axios';
import LoginUtility from '../GlobalModules/Login/Utility/LoginUtility'
import Utility from './Utility'
import { createHashHistory } from 'history';

// get api api configure fn
export const get = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		var params = data ? data.filters : {};
		let invokeError = true;
		if (typeof params == "string") {
			params = JSON.parse(params)
			if (params.size_width && params.size_height) invokeError = false;
		}
		axios.get(apiBaseUrl, {
			params: data,
			headers: getHeaders(header, url)
		}).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			if (invokeError) {
				errorHandlingBlock(error)
			}
		})
	});
}

// post api api configure fn
export const post = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.post(apiBaseUrl, data, { headers: getHeaders(header, url) }).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}

// patch api api configure fn
export const patch = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.patch(apiBaseUrl, data, { headers: getHeaders(header, url) }).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}

// put api api configure fn
export const put = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.put(apiBaseUrl, data, { headers: getHeaders(header, url) }).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}

// delete api api configure fn
export const del = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.delete(apiBaseUrl, {
			data: data,
			headers: getHeaders(header, url)
		}).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}

// download api configure fn
export const download = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.get(apiBaseUrl, {
			params: data,
			headers: getHeaders(header, url),
			responseType: 'arraybuffer'
		}).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}

//Api header configure fn
export const getHeaders = (header, path = "") => {
	checkExpiryOfToken()
	// let headers = { 'ngrok-skip-browser-warning': 'true' }
	let headers = {}
	headers['language'] = localStorage.getItem('i18nextLng');
	if (![`${process.env.EXTENDED_URL}users/login`, `${process.env.EXTENDED_URL}admin/user_details`].includes(path)) {
		headers['business_details'] = JSON.stringify(Store.default.getState().LoginReducer.userEntryType);
	}
	if (header == null) {
		headers['language'] = localStorage.getItem('i18nextLng');
	} else if (header == "global") {
		if (path == `${process.env.EXTENDED_URL}users/mfa/verify`) {
			headers["Session"] = Store.default.getState().LoginReducer.session
		} else if (path == `${process.env.EXTENDED_URL}users/usr_mfa_assign`) {

		} else if (path == `${process.env.EXTENDED_URL}users/associate_software_token`) {
			headers["AccessToken"] = Store.default.getState().LoginReducer.accessToken;
		} else if (path == `${process.env.EXTENDED_URL}users/verify_software_token`) {
			headers["AccessToken"] = Store.default.getState().LoginReducer.accessToken;
		} else {
			headers["Authorization"] = Store.default.getState().LoginReducer.token;
		}
	} else if (Object.keys(header).length > 0) {
		if (path == `${process.env.EXTENDED_URL}users/userforcepasswordchange`) {
			Object.keys(header).map((key, idx) => {
				headers[key] = header[key];
			})
		} else if (path == `${process.env.EXTENDED_URL}users/signout`) {
			localStorage.setItem('i18nextLng', 'en');
			headers["Authorization"] = Store.default.getState().LoginReducer.token;
			headers["Accesstoken"] = Store.default.getState().LoginReducer.accessToken;
		} else if (path == `${process.env.EXTENDED_URL}users/changepassword`) {
			headers["Authorization"] = Store.default.getState().LoginReducer.token;
			headers["Accesstoken"] = Store.default.getState().LoginReducer.accessToken;
		}
	}
	return headers
};

//id token expiration time check fn before every api call hits
export const checkExpiryOfToken = () => {
	let currentDateTime = new Date();
	const expiryTime = new Date(Store.default.getState().LoginReducer.expiresIn.expiryTime)
	const loggedInTime = new Date(Store.default.getState().LoginReducer.expiresIn.loggedInTime)
	const expiryInterval = Store.default.getState().LoginReducer.expiresIn.expiryInterval
	if (expiryTime != "") {
		let deltaDifference = ((currentDateTime == "" ? 0 : currentDateTime) - (loggedInTime == "" ? 0 : loggedInTime)) / 1000
		if (currentDateTime >= expiryTime && (deltaDifference <= expiryInterval)) {
			refershToken()
		}
	}
};

//refershToken create api method
export const refershToken = () => {
	const history = createHashHistory();
	let res = new Promise((resolve, reject) => {
		let header = {};
		header["Authorization"] = Store.default.getState().LoginReducer.token;
		header["refreshToken"] = Store.default.getState().LoginReducer.refreshToken;
		let apiBaseUrl = `${process.env.BASE_URL}${process.env.EXTENDED_URL}users/refreshtoken`;
		let data = {}
		// data["refreshToken"] = Store.default.getState().LoginReducer.refreshToken;
		axios.patch(apiBaseUrl, data, { headers: header }).then((response) => {
			resolve(response);
		}).catch((error) => {
			logoutApp()
		})
	});
	res.then((result) => {
		let finalResponse = result.data
		if (finalResponse.success) {
			const finalIdToken = finalResponse.data.tokenType + ' ' + finalResponse.data.idToken;
			const accessToken = finalResponse.data.accessToken
			const expiresIn = LoginUtility.getExpiryDetails(finalResponse.data.expiresIn)
			Store.default.dispatch({
				type: LoginActionTypes.SET_TOKEN,
				payload: finalIdToken
			});
			Store.default.dispatch({
				type: LoginActionTypes.SET_ACCESS_TOKEN,
				payload: accessToken
			});
			Store.default.dispatch({
				type: LoginActionTypes.SET_TOKEN_EXPIRE_TIME,
				payload: expiresIn
			});
		} else {
			Utility.toastNotifications("Oops session has been expired!!!", "Error", "error")
			logoutApp()
		}
	})

};

//logoutApp fn
export const logoutApp = async () => {
	localStorage.setItem('i18nextLng', 'en');
	const history = createHashHistory();
	try {
		const header = {
			"Authorization": Store.default.getState().LoginReducer.token,
			"Accesstoken": Store.default.getState().LoginReducer.accessToken
		};
		const apiBaseUrl = `${process.env.BASE_URL}${process.env.EXTENDED_URL}users/signout`;
		await axios.delete(apiBaseUrl, {
			data: {},
			headers: header
		});
		Store.default.dispatch({
			type: LoginActionTypes.LOGOUT,
			payload: { "token": "", "accessToken": "", "refreshToken": "", "userGroup": [], "organisationGroup": [], "userEntryType": "", "expiresIn": { "loggedInTime": "", "expiryTime": "", "expiryInterval": "" }, "userCredentials": {}, version: [], "roleWisePermission": {} }
		});
		Store.default.dispatch({
			type: AllActionTypes.CLEAR_DATA,
			payload: { "loaderState": false, "leftbar": false, "activeLink": { 'accName': "", 'activeClass': "" }, "roleWisePermission": {} }
		});
		history.push(`/${localStorage.getItem('i18nextLng')}/login`);
		localStorage.clear();
		window.location.reload();
	} catch (error) {
	}
};

//error handler fn for api errors
export const errorHandlingBlock = (error) => {
	if (error.response) {
		if (error.response.status == 413) {
			if (Array.isArray(error.response.data)) {
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
			} else {
				Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}

		if (error.response.status == 409) {
			if (Array.isArray(error.response.data)) {
				if (error.response.data.length > 0) {
					error.response.data.map((item) => {
						Utility.toastNotifications(item.message, "Error", "error");
					});
				}
			} else {
				Utility.toastNotifications(error.response.data.message, "Error", "error");
			}
		}

		if (error.response.status == 406) {
			if (Array.isArray(error.response.data)) {
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
			} else {
				if (error.response.data.message == "Please make sure you have done full payments before checkout") {
					Utility.toastNotifications(error.response.data.message, "Warning", "warning");
				} else if (error.response.data.message.indexOf('Check-Out is not possible as payment of amount') !== -1) {
					Utility.toastNotifications(error.response.data.message, "Warning", "warning");
				} else {
					Utility.toastNotifications(error.response.data.message, "Error", "error");
				}
			}
		}
		if (error.response.status == 400) {
			if (Array.isArray(error.response.data)) {
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
			} else {
				if (error.response.data.message != "Customer details already updated") {
					if (error.response.data.message.indexOf("To create a new property, please deactivate an existing branch.") !== -1) {
						Utility.toastNotifications(error.response.data.message, "Warning", "warning");
					} else if (error.response.data.message.indexOf("Cannot proceed booking as active list have more than") !== -1) {
						Utility.toastNotifications(error.response.data.message, "Warning", "warning");
					} else {
						Utility.toastNotifications(error.response.data.message, "Error", "error")
					}
				}
			}
		}

		if (error.response.status == 404) {
			if (Array.isArray(error.response.data)) {
				if (error.response.data[0].message)
					if (error.response.data[0].message.indexOf('Please settle them individually first') == -1) {
						Utility.toastNotifications(error.response.data[0].message, "Error", "error");
					}
			} else {
				Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}
		if (error.response.status == 422) {
			if (Array.isArray(error.response.data)) {
				Utility.toastNotifications(error.response.data[0].message, "Warning", "warning");
			} else {
				Utility.toastNotifications(error.response.data.message, "Warning", "warning")
			}
		}

		if (error.response.status == 401) {
			if (error.response.data.message == "The incoming token has expired") {
				Utility.toastNotifications("Oops session has been expired!!!", "Error", "error")
				logoutApp()
			}
			logoutApp()
		} else if (error.response.data) {
			if (error.response.data.message != 'Customer details already updated') {
				Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}
	} else {
		if (error.message != "Customer details already updated") {
			Utility.toastNotifications(error.message, "Error", "error")
		}
	}
}

//set idToken in redux fn
export const setToken = (value) => {
	Store.default.dispatch({
		type: LoginActionTypes.SET_TOKEN,
		payload: value
	})
};
