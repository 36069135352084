import React, { useState, useEffect, useRef } from 'react';
import '../Assets/css/logindoc.scss';
import { connect } from 'react-redux';
const AppLandingPage = (props) => {
	const [state, setState] = useState({
	})

	const setLanguage = () => {
		if (props.token && props.token != "" && props.token != null) {
			props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
		} else {
			props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
		}
	}
	useEffect(() => {
		setLanguage();
	}, [state.loginCredentials]);

	return (
		<></>
	);
}



const mapStateToProps = (globalState) => {
	return {
		token: globalState.LoginReducer.token
	};
}

export default connect(mapStateToProps)(AppLandingPage);


