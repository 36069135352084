import React, { Component } from 'react';
import CustomInput from '../../../../Utility/Components/CustomInput';
import PhoneNumberWithCountryCode from '../../../../Utility/Components/PhoneNumberWithCountryCode';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp, userrole } from '../../../Login/Actions/LoginAction';
import * as PersonalController from './Controller/PersonalController'
import Utility from '../../../../Utility/Utility';
import moment from 'moment';
import ValidateUtility from '../../../../Utility/ValidateUtility'
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import { withNamespaces } from 'react-i18next';
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import { Country, State, City } from 'country-state-city';
import parsePhoneNumber from 'libphonenumber-js'

class Personal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setEditDataSet: {},
			CountryList: [],
			personalTabFormData: {
				email: "",
				contact_number: "",
				address1: "",
				address2: "",
				city: "",
				zip_code: "",
				country: "",
				date_of_birth: "",
				identity_type: "",
				identity_number: "",
				identity_scan_img_url: "",
				gstin_number: ""
			},
			personalTabFormDataError: {
				email: "",
				contact_number: "",
				address1: "",
				address2: "",
				city: "",
				zip_code: "",
				country: "",
				date_of_birth: "",
				identity_type: "",
				identity_number: "",
				identity_scan_img_url: "",
				gstin_number: ""
			},
			identity_type_list: [
				{ label: "PAN Card", value: "pan_card" },
				{ label: "Aadhaar card", value: "aadhar_card" },
				{ label: "Identity card", value: "identity_card" },
				{ label: "Driving licence", value: "driving_licence" }
			],
			selected_identity_type: {},
			button_enabled: false,
			documentObjFileName: this.props.t('choosefile'),
			documentObjfileObj: "",
			userPersonalId: "",
			userPersonalData: {},
			selected_country: { "label": "India", "value": "IN", "country_code": "91", "iso_code": "IN" },
			selectedCountryCode: "in",
			disable: false,
			phoneNumberCountryCode: "in",
			phoneNumberDialCode: "91"
		}
	}

	//get country list option from country-state-city npm
	getCountryOption = () => {
		if (this.state.CountryList.length == 0) {
			Country.getAllCountries().map((countryObj) => {
				this.setState(prevState => ({
					CountryList: [...prevState.CountryList, { label: countryObj.name, value: countryObj.isoCode, country_code: countryObj.phonecode, iso_code: countryObj.isoCode }]
				}));
			})
		}
	}

	componentDidMount = () => {
		this.userPersonalInfo()
		this.getCountryOption();
		this.fetchTabData();
	}

	//fetch personal , professional tab role permissions wise tab show 
	async fetchTabData() {
		let disableFlag = false
		const personalTabPermission = await Utility.getRoleObjectBySubEventKey('user', 'personal');
		if (this.props.userCredentials.user_details?.user_type === "staff" && personalTabPermission.length > 0 && personalTabPermission[0].rules == "R") {
			disableFlag = true
		}
		this.setState({
			disable: disableFlag
		});
	}

	//user personal data get api
	userPersonalInfo = () => {
		const { loaderStateTrue, loaderStateFalse, userProfileId } = this.props;
		loaderStateTrue();
		PersonalController.userPersonalInfoGet(userProfileId).then((response) => {
			if (response.success) {
				this.setState({
					userPersonalId: response.data[0].hasOwnProperty('UserPersonalInfo') ? response.data[0].UserPersonalInfo.id : "",
					userPersonalData: response.data[0].hasOwnProperty('UserPersonalInfo') ? response.data[0].UserPersonalInfo : {},
				}, () => {
					this.props.phoneNumberUpdateSuccessFn(response.data[0].hasOwnProperty('UserPersonalInfo') ? response.data[0].UserPersonalInfo : {})
					this.setUserPersonalInfoData()
				})
			}
			loaderStateFalse();
		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
		});
	}

	//update personal details fn
	personalfromSubmitData = () => {
		this.updatePersonalDetails()

	}

	//update personal details api
	updatePersonalDetails = () => {
		let valid = this.validationFromData();
		if (valid) {
			const { loaderStateTrue, loaderStateFalse } = this.props;
			let data = this.formatingDataset();
			data = data[0];
			let type = 'patch'
			loaderStateTrue();
			PersonalController.personalDetailsUpdate(data, type, this.state.userPersonalId).then((response) => {
				loaderStateFalse();
				if (response) {
					if (response.success) {
						this.props.detectChangesIndividualTab(false)
						this.props.detectChangesIndividualInnerTab(false)
						this.userPersonalInfo()
						this.setState({
							button_enabled: false
						}, () => {
						})
						Utility.toastNotifications(response.message, "Success", "success");
					} else {
						Utility.toastNotifications(response.message, "Error", "error")
					}
				}
			}).catch((error) => {
				console.error("************error*************", error)
				if (error) {
				}
				loaderStateFalse();
				if (error.message == "Network Error") {

				}
			});
		}
	}

	//cancel personal tab changes fn
	cancelChangesPersonalTab = () => {
		this.props.detectChangesIndividualTab(false)
		this.props.detectChangesIndividualInnerTab(false)
		this.userDetails()
		this.setState({
			button_enabled: false
		}, () => { })
	}

	//personal tab input fields onchange fn
	handelChange = (e, type, countryCode = {}) => {
		this.props.detectChangesIndividualTab(true)
		this.props.detectChangesIndividualInnerTab(true)
		const { personalTabFormData, personalTabFormDataError } = this.state;
		let tempFormData = Object.assign(personalTabFormData);
		let tempErrorFormData = Object.assign(personalTabFormDataError)

		if (type == "contact_number") {
			if (e.trim() !== "") {
				let modifiedString = ValidateUtility.deleteAndBefore(e, countryCode.dialCode);
				let countryCodeValue = countryCode.countryCode.toUpperCase();
				const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
				this.setState({
					phoneNumberCountryCode: countryCode.countryCode,
					phoneNumberDialCode: countryCode.dialCode
				})

				if (phoneNumber && phoneNumber.isValid()) {
					tempFormData["contact_number"] = e
					tempErrorFormData['contact_number'] = ""
				} else {
					tempFormData["contact_number"] = e
					tempErrorFormData['contact_number'] = "Enter valid phone number"
				}
			} else {
				tempFormData["contact_number"] = "";
				tempErrorFormData['contact_number'] = "Required field";
			}
		}

		if (type == "email") {
			if (e.target.value != "") {
				let emailValidate = ValidateUtility.validate_email(e.target.value);
				if (emailValidate) {
					tempFormData["email"] = e.target.value,
						tempErrorFormData['email'] = ""
				} else {
					tempFormData["email"] = e.target.value,
						tempErrorFormData['email'] = "Enter valid email"
				}
			} else {
				tempFormData["email"] = "",
					tempErrorFormData['email'] = ""
			}
		}

		if (type == "address1") {
			var expr = /^[a-zA-Z0-9 ,/.-]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['address1'] = ""
					tempFormData["address1"] = ""
				} else {
					tempFormData["address1"] = e.target.value
					tempErrorFormData['address1'] = ""
				}
			}
		}

		if (type == "address2") {
			var expr = /^[a-zA-Z0-9 ,/.-]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['address2'] = ""
					tempFormData["address2"] = ""
				} else {
					tempFormData["address2"] = e.target.value
					tempErrorFormData['address2'] = ""
				}
			}
		}

		if (type == "city") {
			var expr = /^[a-zA-Z ]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['city'] = ""
					tempFormData["city"] = ""
				} else {
					tempFormData["city"] = e.target.value
					tempErrorFormData['city'] = ""
				}
			}
		}

		if (type == "zip_code") {
			if (e.target.value == "") {
				tempErrorFormData['zip_code'] = ""
				tempFormData["zip_code"] = ""
			} else {
				var sanitizedValue = e.target.value.replace(/\D/g, '');
				tempFormData["zip_code"] = sanitizedValue
				tempErrorFormData['zip_code'] = ""
			}
		}
		if (type == "country") {
			if (e) {
				let countryCode = e.value.toLowerCase().toString()
				if (this.state.selectedCountryCode.toLowerCase().toString() != countryCode) {
					tempErrorFormData['country'] = ""
					tempFormData["country"] = e.value.toLowerCase().toString()
					tempFormData["contact_number"] = ""

					this.setState({
						selected_country: e,
						selectedCountryCode: countryCode
					})
				}
			} else {
				tempFormData["country"] = ""
				tempErrorFormData['country'] = ""
				tempFormData["contact_number"] = ""
				this.setState({
					selected_country: { "label": "India", "value": "IN", "country_code": "91", "iso_code": "IN" }
				})
			}
		}

		if (type == "gstin_number") {
			if (e.target.value == "") {
				tempErrorFormData['gstin_number'] = ""
				tempFormData["gstin_number"] = ""
			} else {
				let gstnValidate = ValidateUtility.validate_gstn_Number(e.target.value);
				if (gstnValidate) {
					tempFormData["gstin_number"] = e.target.value.toUpperCase();
					tempErrorFormData['gstin_number'] = ""

				} else {
					tempFormData["gstin_number"] = e.target.value.toUpperCase();
					tempErrorFormData['gstin_number'] = `${this.props.t('validgstnnumber')}`
				}
			}
		}

		if (type == "dob") {
			if (e == "") {
				tempErrorFormData['date_of_birth'] = ""
				tempFormData["date_of_birth"] = ""
			} else {
				tempFormData["date_of_birth"] = e
				tempErrorFormData['date_of_birth'] = ""
			}
		}

		if (type == 'identity_type') {
			if (e) {
				tempFormData["identity_type"] = e;
				tempErrorFormData['identity_type'] = "";
				tempFormData['identity_number'] = "";
				tempErrorFormData['identity_number'] = "";
				this.setState({
					selected_identity_type: e
				})
			} else {
				tempFormData["identity_type"] = "";
				tempErrorFormData['identity_type'] = "";
				tempFormData['identity_number'] = "";
				tempErrorFormData['identity_number'] = ""
				this.setState({
					selected_identity_type: {}
				})

			}
		}


		if (this.state.selected_identity_type && Object.keys(this.state.selected_identity_type).length > 0 && this.state.selected_identity_type.value == "pan_card") {
			if (type == "identity_number") {
				if (e.target.value == "") {
					tempFormData['identity_number'] = "";
					tempErrorFormData['identity_number'] = ""
				} else {
					let panValidate = ValidateUtility.validate_pan_Number(e.target.value);
					if (panValidate) {
						tempFormData['identity_number'] = e.target.value.toUpperCase();
						tempErrorFormData['identity_number'] = ""

					} else {
						tempFormData['identity_number'] = e.target.value.toUpperCase();;
						tempErrorFormData['identity_number'] = this.props.t('validpannumber')
					}
				}
			}
		}
		if (this.state.selected_identity_type && Object.keys(this.state.selected_identity_type).length > 0 && this.state.selected_identity_type.value == "aadhar_card") {
			if (type == "identity_number") {
				if (e.target.value == "") {
					tempFormData['identity_number'] = ""
					tempErrorFormData['identity_number'] = ""
				} else {
					let panValidate = ValidateUtility.validate_aadhar_number(e.target.value);
					if (panValidate) {
						tempFormData['identity_number'] = e.target.value;
						tempErrorFormData['identity_number'] = ""

					} else {
						tempFormData['identity_number'] = e.target.value;
						tempErrorFormData['identity_number'] = this.props.t('valid_aadhar_number')
					}
				}
			}
		}

		if (this.state.selected_identity_type && Object.keys(this.state.selected_identity_type).length > 0 && this.state.selected_identity_type.value == "identity_card") {
			if (type == "identity_number") {
				if (e.target.value == "") {
					tempFormData['identity_number'] = "";
					tempErrorFormData['identity_number'] = ""
				} else {
					let panValidate = ValidateUtility.validate_identity_card_number(e.target.value);
					if (panValidate) {
						tempFormData['identity_number'] = e.target.value;
						tempErrorFormData['identity_number'] = ""

					} else {
						tempFormData['identity_number'] = e.target.value;
						tempErrorFormData['identity_number'] = this.props.t('valid_identity_number')
					}
				}
			}
		}

		if (this.state.selected_identity_type && Object.keys(this.state.selected_identity_type).length > 0 && this.state.selected_identity_type.value == "driving_licence") {
			if (type == "identity_number") {
				if (e.target.value == "") {
					tempFormData['identity_number'] = "";
					tempErrorFormData['identity_number'] = ""
				} else {
					let panValidate = ValidateUtility.validate_driving_licence_number(e.target.value);
					if (panValidate) {
						tempFormData['identity_number'] = e.target.value;
						tempErrorFormData['identity_number'] = ""

					} else {
						tempFormData['identity_number'] = e.target.value;
						tempErrorFormData['identity_number'] = this.props.t('valid_driving_licence_number')
					}
				}
			}
		}

		this.setState({
			personalTabFormData: tempFormData,
			personalTabFormDataError: tempErrorFormData,
			button_enabled: true
		})
	}

	//validate personal tab data
	validationFromData = () => {
		const { personalTabFormData, personalTabFormDataError } = this.state;
		let tempFormData = Object.assign(personalTabFormData);
		let tempErrorFormData = Object.assign(personalTabFormDataError)

		let valid = true
		if (tempFormData.email == null || tempFormData.email == "") {
			tempErrorFormData['email'] = "Required field"
			valid = false
		}

		if (tempFormData.contact_number == null || tempFormData.contact_number == "") {
			tempErrorFormData['contact_number'] = "Required field"
			valid = false
		} else {
			let modifiedString = ValidateUtility.deleteAndBefore(tempFormData.contact_number, this.state.phoneNumberDialCode);
			let countryCodeValue = this.state.phoneNumberCountryCode.toUpperCase();
			const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
			if (phoneNumber && phoneNumber.isValid()) {
				tempErrorFormData['contact_number'] = ""
			} else {
				tempErrorFormData['contact_number'] = "Enter valid phone number"
				valid = false
			}
		}

		if (tempFormData.identity_type != null) {
			if (tempFormData.identity_type && tempFormData.identity_type.value == "pan_card") {
				let panValidate = ValidateUtility.validate_pan_Number((tempFormData.identity_number).trim());
				if (panValidate) {
					tempErrorFormData['identity_number'] = ""

				} else {
					tempErrorFormData['identity_number'] = this.props.t('validpannumber')
					valid = false;
				}
			}
			if (tempFormData.identity_type && tempFormData.identity_type.value == "aadhar_card") {
				let panValidate = ValidateUtility.validate_aadhar_number((tempFormData.identity_number).trim());
				if (panValidate) {
					tempErrorFormData['identity_number'] = ""
				} else {
					valid = false;
					tempErrorFormData['identity_number'] = this.props.t('valid_aadhar_number')
				}
			}
			if (tempFormData.identity_type && tempFormData.identity_type.value == "identity_card") {
				let panValidate = ValidateUtility.validate_identity_card_number((tempFormData.identity_number).trim());
				if (panValidate) {
					tempErrorFormData['identity_number'] = ""
				} else {
					valid = false;
					tempErrorFormData['identity_number'] = this.props.t('valid_identity_number')
				}
			}
			if (tempFormData.identity_type && tempFormData.identity_type.value == "driving_licence") {
				let panValidate = ValidateUtility.validate_driving_licence_number((tempFormData.identity_number).trim());
				if (panValidate) {
					tempErrorFormData['identity_number'] = ""

				} else {
					valid = false;
					tempErrorFormData['identity_number'] = this.props.t('valid_driving_licence_number')
				}
			}
		}

		this.setState({
			personalTabFormDataError: tempErrorFormData
		})
		return valid
	}

	//remove plus sign from user phone number
	removePlusSymbol = (inputString) => {
		if (inputString.includes('+')) {
			return inputString.replace('+', '');
		} else {
			return inputString;
		}
	}

	//personal tab dataset formatting fn
	formatingDataset = () => {
		const { personalTabFormData } = this.state
		let dataset = [];
		let hash = {}

		if (personalTabFormData.email != "") {
			hash['email'] = personalTabFormData.email;
		}
		if (personalTabFormData.contact_number != "") {
			hash['contact'] = this.removePlusSymbol(personalTabFormData.contact_number);
		}
		if (personalTabFormData.address1 != "" && personalTabFormData.address1 != null) {
			hash['address1'] = (personalTabFormData.address1).trim();
		} else {
			hash['address1'] = "";
		}
		if (personalTabFormData.address2 != "" && personalTabFormData.address2 != null) {
			hash['address2'] = (personalTabFormData.address2).trim();
		} else {
			hash['address2'] = "";
		}
		if (personalTabFormData.city != "" && personalTabFormData.city != null) {
			hash['city'] = (personalTabFormData.city).trim();
		} else {
			hash['city'] = "";
		}
		if (personalTabFormData.zip_code != "" && personalTabFormData.zip_code != null) {
			hash['zip'] = (personalTabFormData.zip_code).trim();
		} else {
			hash['zip'] = "";
		}
		if (personalTabFormData.country != "" && personalTabFormData.country != null) {
			hash['country'] = (personalTabFormData.country).trim();
		} else {
			hash['country'] = "";
		}
		if (personalTabFormData.date_of_birth != "" && personalTabFormData.date_of_birth != null) {
			hash['date_of_birth'] = moment(personalTabFormData.date_of_birth.toString()).format('YYYY-MM-DD');
		} else {
			hash['date_of_birth'] = "";
		}
		if (personalTabFormData.identity_type != "" && personalTabFormData.identity_type != null && personalTabFormData.identity_type != undefined) {
			hash['identity_type'] = personalTabFormData.identity_type.value;
		} else {
			hash['identity_type'] = "";
		}
		if (personalTabFormData.identity_number != "" && personalTabFormData.identity_number) {
			hash['identity_number'] = (personalTabFormData.identity_number).trim();
		} else {
			hash['identity_number'] = "";
		}
		if (this.state.documentObjfileObj != "") {
			hash['identity_scan_img'] = this.state.documentObjfileObj;
		}

		hash['user_id'] = this.props.userProfileId;

		dataset.push(hash)
		return dataset;

	}

	//profile identity scan image upload onchange fn
	uploadDocumentFileChange = (event) => {
		if (event.target.files.length == 1) {
			let fileName = event.target.files[0].name.split(".")
			let extension = fileName.pop()
			if (extension.toLowerCase() == "jpg" || extension.toLowerCase() == "jpeg" || extension.toLowerCase() == "png") {
				const fsize = event.target.files[0].size;
				const file = Math.round((fsize / 1024));
				if (file >= 300) {
					Utility.toastNotifications(this.props.t('imageUploadAlert'), "Warning", "warning");
				} else {
					this.props.detectChangesIndividualTab(true)
					this.props.detectChangesIndividualInnerTab(true)
					this.getBase64MarketedLogoUrl(event.target.files[0], (result) => {
						let _hash = {}
						_hash['file_name'] = event.target.files[0].name
						_hash['file_obj'] = result
						this.setState({
							documentObjfileObj: _hash,
							documentObjFileName: event.target.files[0].name,
							button_enabled: true
						})
					})
				}
			} else {
				Utility.toastNotifications("File format is not supported, it should be in jpeg or png format", "Warning", "warning");
			}
		} else {
			this.setState({
				documentObjfileObj: "",
				documentObjFileName: this.props.t('choosefile'),
				button_enabled: false
			})
		}
	}

	//get base64 image from file
	getBase64MarketedLogoUrl(file, cb) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
		};
	}


	//set personal information data fn
	setUserPersonalInfoData = () => {
		const { userPersonalData } = this.state
		if (Object.keys(userPersonalData).length > 0) {
			let dateOfBirth = ""
			if (userPersonalData.date_of_birth == null || userPersonalData.date_of_birth == "" || userPersonalData.date_of_birth == "Invalid date") {
				dateOfBirth = "";
			} else {
				dateOfBirth = new Date(userPersonalData.date_of_birth);
			}
			let identityTypeListFilter = this.state.identity_type_list.filter((el) => {
				return el.value == userPersonalData.identity_type;
			});

			var countryListFilter = [{ "label": "India", "value": "IN", "country_code": "91", "iso_code": "IN" }]
			if (userPersonalData.country != null && userPersonalData.hasOwnProperty('country')) {
				countryListFilter = this.state.CountryList.filter((el) => {
					return el.value.toLowerCase() == userPersonalData.country.toLowerCase();
				});
			}

			this.setState({
				selectedCountryCode: countryListFilter[0].value.toLowerCase().toString(),
			}, () => {
				this.setState({
					personalTabFormData: {
						email: userPersonalData.email != "" ? userPersonalData.email : "",
						contact_number: userPersonalData.contact != "" ? userPersonalData.contact : "",
						address1: userPersonalData.address1 != "" ? userPersonalData.address1 : "",
						address2: userPersonalData.address2 != "" ? userPersonalData.address2 : "",
						city: userPersonalData.city != "" ? userPersonalData.city : "",
						zip_code: userPersonalData.zip != "" ? userPersonalData.zip : "",
						country: countryListFilter[0].value,
						date_of_birth: dateOfBirth,
						identity_type: identityTypeListFilter[0],
						identity_number: userPersonalData.identity_number != "" ? userPersonalData.identity_number : "",
						identity_scan_img_url: userPersonalData.identity_scan_img_url != "" ? userPersonalData.identity_scan_img_url : "",
					},
					selected_identity_type: identityTypeListFilter[0],
					selected_country: countryListFilter[0],
					documentObjFileName: userPersonalData.hasOwnProperty('identity_scan_img_url') && userPersonalData.identity_scan_img_url != null && userPersonalData.identity_scan_img_url != "" ? JSON.parse(userPersonalData.identity_scan_img_url).file_name : this.props.t('choosefile')
				}, () => {
				})
			})


		}
	}

	render() {
		const { personalTabFormData, personalTabFormDataError, selectedCountryCode, } = this.state;
		const eighttenYearsAgo = new Date();
		eighttenYearsAgo.setFullYear(eighttenYearsAgo.getFullYear() - 18);
		return (
			<div className="profilecontainer profilepersonalcon userWrite general_profilepersonalcon">
				<div className="inputviewinner">
					<div className="jbssadn_en_ndassbj">
						<div className={personalTabFormDataError.contact_number != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Contact</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<PhoneNumberWithCountryCode
										disabled={this.state.disable}
										country={selectedCountryCode}
										value={personalTabFormData.contact_number}
										onChange={(e, country) => this.handelChange(e, 'contact_number', country)}
										//error={}
										labelShow={false}
										disableDropdown={true}
									/>

								</ErrorBoundary>
							</div>
						</div>
						<div className={personalTabFormDataError.email != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Email</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={personalTabFormData.email}
										onChange={(e) => this.handelChange(e, 'email')}
										readOnly={true}
									/>
								</ErrorBoundary>
							</div>
						</div>
						<div className={personalTabFormDataError.address1 != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Address 1</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={personalTabFormData.address1}
										onChange={(e) => this.handelChange(e, 'address1')}
										readOnly={this.state.disable}
									//disabled={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>
						<div className={personalTabFormDataError.address2 != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Address 2</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={personalTabFormData.address2}
										onChange={(e) => this.handelChange(e, 'address2')}
										readOnly={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>
						<div className={personalTabFormDataError.city != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">City</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={personalTabFormData.city}
										onChange={(e) => this.handelChange(e, 'city')}
										readOnly={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>
						<div className={personalTabFormDataError.zip_code != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Zip Code</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={personalTabFormData.zip_code}
										onChange={(e) => this.handelChange(e, 'zip_code')}
										readOnly={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>

						<div className={personalTabFormDataError.country != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Country</div>
							<div className="inputfieldview profiledropdown">
								<ErrorBoundary title="CustomInput Error">
									<AutosuggestComponent
										handleOnChange={(e) => this.handelChange(e, 'country')}
										options={this.state.CountryList}
										selectedValue={this.state.selected_country}
										name=''
										isMulti={false}
										placeholder=""
										isDisabled={this.state.disable}
										isSearchable={true}
										classNamePrefix="dropdown-personal"
									/>
								</ErrorBoundary>
							</div>
						</div>

						<div className={personalTabFormDataError.date_of_birth != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Date of Birth</div>
							<div className="inputfieldview datepickerrow">
								<ErrorBoundary title="CustomInput Error">
									<CommonDateTimePicker
										selected={personalTabFormData.date_of_birth}
										onChange={(e) => this.handelChange(e, 'dob')}
										showTimeSelect={false}
										dateFormat="dd-MM-yyyy"
										placeholderText="Select date"
										minDate=""
										maxDate={eighttenYearsAgo}
										disabled={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>
						<div className={personalTabFormDataError.identity_type != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Identity Type</div>
							<div className="inputfieldview profiledropdown">
								<ErrorBoundary title="CustomInput Error">
									<AutosuggestComponent
										handleOnChange={(e) => this.handelChange(e, 'identity_type')}
										options={this.state.identity_type_list}
										selectedValue={this.state.selected_identity_type}
										name=''
										isMulti={false}
										placeholder=""
										isDisabled={this.state.disable}
										isSearchable={true}
										classNamePrefix="dropdown-personal dropdown-default"
										isClearable={this.state.selected_identity_type && Object.keys(this.state.selected_identity_type).length > 0 ? true : false}
									/>
								</ErrorBoundary>
							</div>
						</div>

						<div className={personalTabFormDataError.identity_number != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Identity Number</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={personalTabFormData.identity_number}
										onChange={(e) => this.handelChange(e, 'identity_number')}
										readOnly={this.state.disable ? true : this.state.selected_identity_type && Object.keys(this.state.selected_identity_type).length > 0 ? false : true}
									/>
								</ErrorBoundary>
							</div>
						</div>

						<div className="inputrowbox">
							<div className="inputlabelview">Identity Scan Img</div>
							<div className="inputfieldview fileUploadInnerBox identity_scan_img_upload">
								<div className="uploadfiledoc">
									<div className="file_name_property">
										<label className="custom-file-upload">
											<span>
												{this.state.documentObjFileName}
											</span>
											<input type="file" onChange={this.uploadDocumentFileChange} disabled={this.state.disable} />
										</label>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div className="rightsideview">
					{this.state.button_enabled &&
						<button type="button" className="submitbtnprofile" onClick={this.personalfromSubmitData}>Submit</button>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (globalState) => {
	return {
		userCredentials: globalState.LoginReducer.userCredentials,
		roleWisePermission: globalState.mainReducerData.roleWisePermission

	};
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, setUserCredentials, logOutApp })
	(withNamespaces()(Personal));