import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import LoginUtility from '../../../Utility/Utility';
import { loaderStateTrue, loaderStateFalse, roleWisePermission } from '../../../Actions/AllAction';
import { setToken, setUserEntryType, setUserCredentials, logOutApp, setVersionList } from '../Actions/LoginAction';
import { Modal } from 'react-bootstrap';
import CommonLogin from '../Components/CommonLogin';
import * as LoginController from '../Controller/LoginController';
import '../Assets/css/logindoc.scss';
import PropTypes from 'prop-types';
import ModalGlobal from '../../../Utility/Components/ModalGlobal';
import ConfirmationAlert from '../../../Utility/Components/ConfirmationAlert';
import { version } from 'react-dom/cjs/react-dom.production.min';
import logger from '../../../logger';
import { withNamespaces } from 'react-i18next';
import i18n from '../../../i18n';
import Cookies from 'js-cookie';
import ErrorBoundary from '../../../Utility/Components/ErrorBoundary';
import SmsVerification from '../Components/SmsVerification';

const LoginPage = (props) => {
	const { loaderStateTrue, loaderStateFalse, history, logOutApp, setToken, setUserEntryType, setUserCredentials, roleWisePermission } = props;
	const [email, setemail] = useState(Cookies.get('rememberMe') == 'true' ? Cookies.get('email') : "")
	const [password, setpassword] = useState(Cookies.get('rememberMe') == 'true' ? Cookies.get('password') : "")
	const [updated_password, setupdated_password] = useState("")
	const [updated_confirm_password, setupdated_confirm_password] = useState("")
	const [isRemember, setIsRemember] = useState(Cookies.get('rememberMe') == 'true' ? true : false)
	const [smsVerificationModalContentFlag, setSmsVerificationModalContentFlag] = useState(false);
	const [state, setState] = useState({
		emailError: "",
		passwordError: "",
		updated_passwordError: "",
		updated_comfirm_passwordError: "",
		passwordLock: false,
		confirmPasswordLock: false,
		passtype: "password",
		confirmPasstype: "password",
		loginCredentials: {},
		//modal
		modalShow: false,
		updatedPasswordSession: "",
		challangeName: "",
		userName: "",

		// Focus
		emailFocus: false,
		passwordFocus: false,

		// Language change
		langaugeConfirmModal: false,
		selectedLangauge: "",
		changePasswordLock: false,
		changePasstype: "password",
		changeConpasstype: "password",
		changeConpasswordLock: false

	})
	const [smsVerficationPopupStatus, setSmsVerficationPopupStatus] = useState("")
	const [challangeName, setChallangeName] = useState("")

	//initially check token then redirect accordingly
	useEffect(() => {
		if (props.token && props.token != "" && props.token != null && props.userEntryType != "") {
			props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
		} else {
			props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
		}
	}, [])

	//email and password fields focus events
	const handleonFocus = (event) => {
		if (event.target.name == "email") {
			setState(prev => ({
				...prev,
				emailFocus: true
			}))
		}

		if (event.target.name == "password") {
			setState(prev => ({
				...prev,
				passwordFocus: true
			}))
		}

	}

	//email and password field blur events 
	const handleonBlur = (event) => {
		setState(prev => ({
			...prev,
			emailFocus: false,
			passwordFocus: false
		}))
	}

	//email and password fields handle change event
	const handleChange = (event) => {
		if (event.target.name == "email") {
			if (event.target.value == "") {
				setState(prev => ({
					...prev,
					emailError: `${props.t('emailError')}`
				}))
				setemail(event.target.value)
			} else {
				setState(prev => ({
					...prev,
					emailError: "",
				}))
				setemail(event.target.value)
			}
			if (isRemember) {
				Cookies.set('email', event.target.value, { expires: 30 });
			} else {
				Cookies.set('email', "", { expires: 0 });
			}
		}
		if (event.target.name == "password") {
			if (event.target.value == "") {
				setState(prev => ({
					...prev,
					passwordError: `${props.t('passwordError')}`
				}))
				setpassword(event.target.value)

			} else {
				setState(prev => ({
					...prev,
					passwordError: "",
				}))
				setpassword(event.target.value)
			}
			if (isRemember) {
				Cookies.set('password', event.target.value, { expires: 30 });
			} else {
				Cookies.set('password', "", { expires: 0 });
			}
		}
		if (event.target.name == "updated_password") {
			setState(prev => ({
				...prev,
				updated_passwordError: ""
			}))

			setupdated_password(event.target.value)
		}
		if (event.target.name == "updated_confirm_password") {
			setState(prev => ({
				...prev,
				updated_comfirm_passwordError: ""
			}))
			setupdated_confirm_password(event.target.value)
		}
	}

	//login api validation fn
	const validation = () => {
		let valid = true;
		if (email.trim() == "") {
			valid = false;
			setState(prev => ({
				...prev,
				emailError: `${props.t('emailError')}`
			}))
		} else {
			var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
			if (!expr.test(email.trim())) {
				setState(prev => ({
					...prev,
					emailError: `${props.t('validEmail')}`
				}))
				valid = false;
			} else {
				setState({
					emailError: ""
				})
			}
		}

		if (password.trim() === "") {
			valid = false;
			setState(prev => ({
				...prev,
				passwordError: "Please enter password"
			}));
		} else if (password.length < 6) {
			valid = false;
			setState(prev => ({
				...prev,
				passwordError: "Password must be at least 6 characters."
			}));
		} else {
			setState(prev => ({
				...prev,
				passwordError: ""
			}));
		}
		return valid;
	}

	//forget password redirection fn
	const forgotPassword = () => {
		props.history.push(`/${localStorage.getItem('i18nextLng')}/forgot_password`);
	}

	//login button hit
	const handleKeyPress = (event) => {
		if (event.keyCode === 13)
			login();
	}

	//login api call
	const login = async (e) => {
		let valid = validation();
		if (valid) {
			loaderStateTrue();
			let data = {}
			data["username"] = email.trim()
			// data["userEnd"] = 'backOffice'
			data["password"] = password.trim()
			LoginController.loginGetApi(data).then(async (response) => {
				loaderStateFalse();
				if (response) {
					if (response.success) {
						//new password set
						if (response.data.challengeName == "NEW_PASSWORD_REQUIRED") {
							setState(prev => ({
								...prev,
								modalShow: true,
								updatedPasswordSession: response.data.session,
								challangeName: response.data.challengeName,
								userName: response.data.username
							}))
						} else if (response.data.challengeName == 'SMS_MFA') {
							//sms mfa set
							setChallangeName('SMS_MFA')
							let session = response.data.session;
							setToken('', '', '', '', [], [], session).then(() => {
								smsVerificationFn();
							});
						} else if (response.data.challengeName == "SOFTWARE_TOKEN_MFA") {
							//authentication app mfa set
							setChallangeName("SOFTWARE_TOKEN_MFA")
							let session = response.data.session;
							setToken('', '', '', '', [], [], session).then(() => {
								smsVerificationFn();
							});
						} else {
							//if mfa is not active
							loginSuccess(response);
						}
					} else {
						if (response.message == "User does not exist.") {
							setState(prev => ({
								...prev,
								passwordError: `${props.t('wrongEmailPassword')}`
							}))

						} else if (response.message == "Password has expired. Please change your password.") {
							props.history.push(`/${localStorage.getItem('i18nextLng')}/forgot_password/${email}`);
						} else {
							setState(prev => ({
								...prev,
								passwordError: response.message,
								passtype: "password",
							}))
						}

					}
				}
			}).catch((error) => {
				loaderStateFalse();
				if (error?.response?.status === 400) {
					if (error?.response?.data?.message == "PreAuthentication failed with error Password has expired. Please re your password..") {
						props.history.push(`/${localStorage.getItem('i18nextLng')}/forgot_password/${email}`);
					} else if (error?.response?.data?.message == 'User is not confirmed.') {
						setSmsVerficationPopupStatus('user_unconfirmed')
						smsVerificationFn();
					}
				}
			});
		}

	}


	//after login successful fn
	const loginSuccess = (response) => {
		const finalIdToken = response.data.tokenType + ' ' + response.data.idToken;
		const accessToken = response.data.accessToken
		const refreshToken = response.data.refreshToken
		const expiresIn = LoginUtility.getExpiryDetails(response.data.expiresIn)
		let userGroup = [];
		const organisationGroup = [];
		setToken(finalIdToken, accessToken, expiresIn, refreshToken, userGroup, organisationGroup, '').then(() => {
			loaderStateTrue();
			setTimeout(async () => {
				let usersssGroup = await getUserOrganisationRelation();
				userGroup = usersssGroup;
				setUserEntryType(userGroup[0]);
				setToken(finalIdToken, accessToken, expiresIn, refreshToken, userGroup, organisationGroup, '').then(() => { });
				getCurrentUSer();
			}, 100);
		})
	}

	//get user organization relations api
	const getUserOrganisationRelation = () => {
		return new Promise((resolve, reject) => {
			const { loaderStateTrue, loaderStateFalse } = props;
			loaderStateTrue();
			let filters = {};
			let globalQueryParamshash = {};
			filters['filters'] = JSON.stringify(globalQueryParamshash)
			LoginController.userOrganisationGet(filters).then((response) => {
				let hotelList = [];
				if (response.success) {
					if (response.data.hasOwnProperty("UserOrgBranchRoleRelation") && response.data.UserOrgBranchRoleRelation) {
						hotelList = response.data.UserOrgBranchRoleRelation;
					}
				}
				loaderStateFalse();
				resolve(hotelList);
			})
		}).catch((error) => {
			reject(error)
		});
	}

	//get current user api fn
	const getCurrentUSer = () => {
		LoginController.getCurrentUser().then((userResponse) => {
			if (userResponse.success) {
				LoginUtility.toastNotificationsDismissAll();
				setState(prev => ({
					...prev,
					loginCredentials: userResponse.data
				}))
				setpassword("")
			} else {
				logOutApp().then(
					() => history.push("/")
				);
				LoginUtility.toastNotifications(userResponse.message, "Error", "error")
			}
		})
		setChallangeName('')
	}

	//set user credentials after getting user credentials api data
	useEffect(() => {
		if (state.loginCredentials && Object.keys(state.loginCredentials).length > 0) {
			setUserCredentialsData();
		}
	}, [state.loginCredentials]);

	//set user credentials in redux and redirect according to the user credentials data
	const setUserCredentialsData = async () => {
		const { loginCredentials } = state
		props.setUserCredentials(loginCredentials).then(() => {
			if (loginCredentials.user_details.user_type == "customer") {
				LoginUtility.toastNotifications("Access denied", 'Error', 'error');
				logOutApp().then(
					() => history.push("/")
				);
			} else {
				if (loginCredentials.user_details.mfa == null && props.userGroup.some(user => user.is_mfa_required === true)) {
					props.history.push(`/${localStorage.getItem('i18nextLng')}/mfa`);
				} else {

					// if (loginCredentials.user_details.user_type == "app_admin") {
					// 	props.history.push(`/${localStorage.getItem('i18nextLng')}/home/organisation`);
					// } else {
					// 	props.history.push(`/${localStorage.getItem('i18nextLng')}/home/user`);
					// }

					if (loginCredentials.hasOwnProperty('user_details') && loginCredentials.user_details.user_type == "app_admin") {
						props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
					} else if (loginCredentials.hasOwnProperty('user_details') && loginCredentials.user_details.user_type == "admin" && loginCredentials.user_details.is_subscribed) {
						props.history.push(`/${localStorage.getItem('i18nextLng')}/dashboard`);
					} else if (loginCredentials.hasOwnProperty('user_details') && loginCredentials.user_details.user_type != "admin" && loginCredentials.user_details.is_subscribed) {
						if (loginCredentials.user_details.user_type == "agent" || loginCredentials.user_details.user_type == "agent_staff") {
							props.history.push(`/${localStorage.getItem('i18nextLng')}/agent/agent_booking`);
						} else {
							if (Object.keys(Utility.getRoleObjectByEventKey('dashboard')).length > 0) {
								props.history.push(`/${localStorage.getItem('i18nextLng')}/dashboard`);
							} else {
								props.history.push(`/${localStorage.getItem('i18nextLng')}/home/branch`);
							}
						}

					} else {
						if (loginCredentials.user_details.user_type == "agent" || loginCredentials.user_details.user_type == "agent_staff") {
							props.history.push(`/${localStorage.getItem('i18nextLng')}/agent/branch`);
						} else {
							props.history.push(`/${localStorage.getItem('i18nextLng')}/home/branch`);
						}
					}
				}
			}
		})
	}

	// show password eye icon onclick handler
	const showText = () => {
		setState(prev => ({
			...prev,
			passwordLock: true,
			passtype: "text"
		}))
	}

	// hide password eye icon onclick handler
	const showPassword = () => {
		setState(prev => ({
			...prev,
			passwordLock: false,
			passtype: "password"
		}))
	}

	//update password modal close fn
	const handleClose = () => {
		loaderStateFalse();
		setState(prev => ({
			...prev,
			modalShow: false,
			updated_password: "",
			updated_confirm_password: "",

			changePasswordLock: false,
			changePasstype: "password",
			changeConpasstype: "password",
			changeConpasswordLock: false
		}));

		setupdated_password("");
		setupdated_confirm_password("")
	}


	//update password api fn
	const updatePassword = () => {
		const { updated_confirm_password, userName, updatedPasswordSession, challangeName } = state;
		let valid = updatePasswordCheck();
		if (valid) {
			loaderStateTrue();
			let header = {};
			header["session"] = updatedPasswordSession;
			let data = {
				"username": email,
				"password": updated_password,
				"challengeName": challangeName
			}
			LoginController.forcePasswordChange(data, header).then((response) => {
				if (response) {
					if (response.success) {
						handleClose();
						LoginUtility.toastNotifications(response.message, "Success", "success")
						setToken("", "", "", "", [], [], '').then(() => {
							setUserCredentials({});
							history.push("/")
						})
					} else {
						LoginUtility.toastNotifications(response.message, "Error", "error")
						loaderStateFalse();
					}
				}
			}).catch((error) => {
				logOutApp().then(
					() => history.push("/")
				);
			});
		}
	}

	//update password api validation check fn
	const updatePasswordCheck = () => {
		let valid = true;
		if (updated_password == "") {
			valid = false;
			setState(prev => ({
				...prev,
				updated_passwordError: props.t('requiredField')
			}))
		}
		if (updated_confirm_password == "") {
			valid = false;
			setState(prev => ({
				...prev,
				updated_comfirm_passwordError: props.t('requiredField')
			}))
		}

		if (updated_password != "" && updated_confirm_password != "") {
			if (updated_password == updated_confirm_password && updated_password != "" && updated_confirm_password != "") {
				setState(prev => ({
					...prev,
					updated_passwordError: '',
					updated_comfirm_passwordError: ''
				}))
			} else {
				valid = false;
				LoginUtility.toastNotifications(props.t('passwordAndConfirmPasswordNotMatch'), "Error", "error");
			}
		}
		return valid;
	}

	//language choose fn
	const lanOnSelect = (lng) => {
		setState(prev => ({
			...prev,
			langaugeConfirmModal: true,
			selectedLangauge: lng
		}))
	}

	//language change modal confirm fn
	const confirmLangaugeChange = () => {
		const { selectedLangauge } = state;
		i18n.changeLanguage(selectedLangauge);
		let splitUrl = props.location.hash.split('/');
		props.history.push(`/${selectedLangauge}/${splitUrl[2]}`);
		window.location.reload();
		setState(prev => ({
			...prev,
			langaugeConfirmModal: false,
		}))
	}

	//cancell language change fn
	const cancelLangaugeChange = () => {
		setState(prev => ({
			...prev,
			langaugeConfirmModal: false,
		}))
	}

	//hide password onclick fn
	const changeShowText = () => {
		setState(prev => ({
			...prev,
			changePasswordLock: true,
			changePasstype: "text"
		}))
	}

	//show password onclick fn
	const changeShowPassword = () => {
		setState(prev => ({
			...prev,
			changePasswordLock: false,
			changePasstype: "password"
		}))
	}

	//hide confirm password onclick fn
	const changeConShowText = () => {
		setState(prev => ({
			...prev,
			changeConpasswordLock: true,
			changeConpasstype: "text"
		}))
	}

	//show confirm password onclick fn
	const changeConShowPassword = () => {
		setState(prev => ({
			...prev,
			changeConpasswordLock: false,
			changeConpasstype: "password"
		}))
	}

	//remember me checkbox onclick fn
	const rememberMe = (e) => {
		if (e.target.checked == true) {
			setIsRemember(e.target.checked)
			Cookies.set('rememberMe', 'true', { expires: 30 });
			Cookies.set('email', email, { expires: 30 });
			Cookies.set('password', password, { expires: 30 });
		}

		if (e.target.checked == false) {
			setIsRemember(e.target.checked)
			Cookies.set('rememberMe', 'false', { expires: 0 });
			Cookies.set('email', "", { expires: 0 });
			Cookies.set('password', "", { expires: 0 });
		}
	}

	//mfa modal open fn
	const smsVerificationFn = () => {
		setSmsVerificationModalContentFlag(true)
	}

	//mfa modal close fn
	const closesmsVerificationFn = () => {
		setSmsVerificationModalContentFlag(false)
		setSmsVerficationPopupStatus("")
	}

	//mfa modal otp verification fn
	const handleSubmitOtpVerfication = (otp) => {
		const { loaderStateTrue, loaderStateFalse } = props;
		if (smsVerficationPopupStatus === 'user_unconfirmed') {
			// User is unconfirmed, send OTP again
			let data = {
				"confirmation_code": otp.join(''),
				"email": email,
				"password": password
			}
			loaderStateTrue();
			LoginController.verifyOtp(data).then((response) => {
				if (response.success) {
					closesmsVerificationFn();
					LoginUtility.toastNotifications(response.message, 'Success', 'success');
					if (type && type == "hotel_details") {
						props.history.push(`/${localStorage.getItem('i18nextLng')}/login/hotel_details`);
					} else {
						props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
					}
				} else {
					LoginUtility.toastNotifications(response.message, 'Error', 'error');
				}
				loaderStateFalse();
			}).catch((error) => {
				loaderStateFalse();
			});
		} else {
			let data = {
				"otp": otp.join(''),
				"username": email,
				"challengeName": challangeName == "SOFTWARE_TOKEN_MFA" ? "SOFTWARE_TOKEN_MFA" : 'SMS_MFA'
			}
			loaderStateTrue();
			LoginController.verifyMfaOtp(data).then((response) => {
				if (response.success) {
					loginSuccess(response);
				} else {
				}
				loaderStateFalse();
			}).catch((error) => {
				loaderStateFalse();
			});
		}
	}

	return (
		<div className="appContainer" id="login_container">
			<CommonLogin
				handleChange={handleChange}
				email={email}
				password={password}
				emailError={state.emailError}
				passwordError={state.passwordError}
				passtype={state.passtype}
				passwordLock={state.passwordLock}
				showText={showText}
				showPassword={showPassword}
				login={login}
				forgotPassword={forgotPassword}
				smsVerificationFn={smsVerificationFn}
				handleonFocus={handleonFocus}
				handleonBlur={handleonBlur}
				emailFocus={state.emailFocus}
				passwordFocus={state.passwordFocus}
				passPlaceholder={props.t('passPlaceholder')}
				forgotTitle={props.t('forgot_password_text')}
				submitTitle={props.t('loginButton')}
				user_name={props.t('user_name')}
				lanOnSelect={lanOnSelect}
				handleKeyPress={handleKeyPress}
				rememberMe={rememberMe}
				isRemember={isRemember}
			/>

			<Modal
				show={state.modalShow}
				onHide={handleClose}
				className="forcePasswordChange changePasswordModalContent"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.t('ChangePasswordButton')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='password-rules-box'>
						<div className='pass-left-box'>
							<div className="passwordRow relativeRow">
								<input type={state.changePasstype == "text" ? "text" : "password"} onChange={handleChange} name="updated_password" value={updated_password} placeholder={props.t('updatepassword')} className="input__fields_property" />
								{!state.changePasswordLock ?
									<span className="passwordEyeIcon" onClick={changeShowText}>
										<i className="fa fa-eye-slash" aria-hidden="true"></i>
									</span>

									:
									<span className="passwordEyeIcon" onClick={changeShowPassword}>
										<i className="fa fa-eye" aria-hidden="true"></i>
									</span>

								}
								<div className="col-md-12 errorClass error_div">{state.updated_passwordError}</div>
							</div>
							<div className="passwordRow relativeRow">
								<input type={state.changeConpasstype == "text" ? "text" : "password"} onChange={handleChange} name="updated_confirm_password" value={updated_confirm_password} placeholder={props.t('updateconfirmpass')} className="input__fields_property" />
								{!state.changeConpasswordLock ?
									<span className="passwordEyeIcon" onClick={changeConShowText}>
										<i className="fa fa-eye-slash" aria-hidden="true"></i>
									</span>

									:
									<span className="passwordEyeIcon" onClick={changeConShowPassword}>
										<i className="fa fa-eye" aria-hidden="true"></i>
									</span>

								}
								<div className="col-md-12 errorClass error_div">{state.updated_comfirm_passwordError}</div>
							</div>
						</div>
						<div className='pass-divider'></div>
						<div className='pass-right-box'>
							<h5>Password Hints:</h5>
							<ul className='password-inner-box'>
								<li><span>Minimum length</span>, which must be at least 6 characters but fewer than 99 characters</li>
								<li><span>Require numbers</span></li>
								<li><span>Require a special character</span> {`from this set: = + - ^ $ * . [ ] { } ( ) ?  ! @ # % & / \ , > < ' : ; | _ ~ `}</li>
								<li><span>Require uppercase letters</span></li>
								<li><span>Require lowercase letters</span></li>
							</ul>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="primary" onClick={updatePassword} className="update_btn">{props.t('submit')}</Button>
				</Modal.Footer>
			</Modal>

			<ModalGlobal
				show={state.langaugeConfirmModal}
				onHide={cancelLangaugeChange}
				className="modalcustomizeDialog confirmationalertmodalDialog"
				bodyClassName="cancelConfirmationbody"
				headerclassName="close_btn_icon"
				title={props.t('languagechange')}
				footer={false}
				body={
					<ConfirmationAlert
						BodyFirstContent={props.t('areYouSureYouWantToReload')}
						confirmationButtonContent={props.t('confirm')}
						deleteConfirmButton={confirmLangaugeChange}
						cancelButtonContent={props.t('cancel')}
						deleteCancleButton={cancelLangaugeChange}
					/>
				}
			/>


			<ErrorBoundary title="ModalGlobal Error">
				<ModalGlobal
					show={smsVerificationModalContentFlag}
					onHide={closesmsVerificationFn}
					title=""
					className="modalcustomizeDialog mondimension smsVerificationModalContent"
					footer={false}
					headerShow={true}
					closeButton={true}
					saveButtonLabel="Confirm"
					saveButtonClassName="btn btn-primary savebtn"
					cancelShow={true}
					body={
						<>
							<ErrorBoundary title="GlobalAddEditModalContent Error">
								<SmsVerification
									formData={{ 'email': email }}
									callFrom={smsVerficationPopupStatus == 'user_unconfirmed' ? '' : challangeName == "SOFTWARE_TOKEN_MFA" ? "SOFTWARE_TOKEN_MFA" : 'SMS_MFA'}
									handleSubmitOtpVerfication={handleSubmitOtpVerfication}
								/>
							</ErrorBoundary>
						</>
					}
				/>
			</ErrorBoundary>

		</div>
	);
}

CommonLogin.propTypes = {
	handleChange: PropTypes.func,
	email: PropTypes.string,
	password: PropTypes.string,
	emailError: PropTypes.string,
	passwordError: PropTypes.string,
	passtype: PropTypes.string,
	passwordLock: PropTypes.bool,
	showText: PropTypes.func,
	showPassword: PropTypes.func,
	login: PropTypes.func,
	forgotPassword: PropTypes.func,
	backgroundImageUrl: PropTypes.string
}


const mapStateToProps = (globalState) => {

	return {
		userCredentials: globalState.LoginReducer.userCredentials,
		token: globalState.LoginReducer.token,
		userEntryType: globalState.LoginReducer.userEntryType,
		userGroup: globalState.LoginReducer.userGroup,
		mfa: globalState?.LoginReducer?.userCredentials?.user_details?.mfa,
	};
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, setUserEntryType, setUserCredentials, logOutApp, roleWisePermission, setVersionList })(withNamespaces()(LoginPage));


