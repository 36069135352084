import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleLeft } from '../../Actions/AllAction';

import { setToken, logOutApp, setUserCredentials, setUserEntryType } from '../../GlobalModules/Login/Actions/LoginAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CustomInput from '../Components/CustomInput';
import * as UtilityController from '../Controller/UtilityController';
import ModalGlobal from './ModalGlobal';
import ConfirmationAlert from './ConfirmationAlert';
import ChangePasswordContent from './ChangePasswordContent';
import AutosuggestComponent from '../Components/AutosuggestComponent';
import { withNamespaces } from 'react-i18next';
import ProfileDetailsContent from './ProfileDetailsContent';
import UserProfile from '../../GlobalModules/UserProfile/Pages/UserProfile'
import Tooltip from 'react-bootstrap/Tooltip';
import AlertNotification from './AlertNotification';
import Utility from '../Utility';
import EventEmitter from '../EventEmitter';
import { getCurrentUser } from '../../GlobalModules/Login/Controller/LoginController';
import { withRouter } from 'react-router-dom';
const channel = new BroadcastChannel('foobar');
class Header extends Component {
	constructor(props) {
		super(props)
		this.handleBar = this.handleBar.bind(this);
		this.state = {
			subheaderShow: true,
			windowWidth: window.innerWidth,
			changePasswordModalOpen: false,
			tabchangeParent: false,
			formData: {
				userEmail: "",
				password: "",
				newPassword: "",
				confirmPassword: ""
			},
			errorData: {
				userEmailError: "",
				passwordError: "",
				newPasswordError: "",
				confirmPasswordError: ""
			},

			limit: 5,
			offset: 0,
			notificationsData: [],
			showLoadmore: false,
			showPopup: false,
			notificationVisible: false,
			langaugeConfirmModal: false,
			selectedLangauge: '',
			changePasswordFlag: false,
			unreadNotificationCount: 0,
			versionOption: [],
			selectedVersionValue: [],

			//alert notification
			show: true,
			notificationData: [{ message: "Your subscription is ended.", "id": 0 }],
			zoominzoomoutflag: "",
			myProfileModalFlag: false,
			isLogout: false,
		}
		this.codeOutsideClickRef = React.createRef();
	}

	//logout functionality
	logout = () => {
		const { logOutApp, history, setToken, setUserCredentials } = this.props;
		logOutApp(this.props.accessToken, this.props.token).then((logoutResponce) => {
			setTimeout(() => {
				this.setState({
					isLogout: true
				}, () => {

					if (logoutResponce.success) {
						localStorage.setItem("selectedTabName", "")
						setToken("", "", "", "", [], [], '').then((res) => {
							setUserCredentials({});
						})
					} else {
						localStorage.setItem("selectedTabName", "")
						setToken("", "", "", "", [], [], '').then(() => {
							setUserCredentials({});

						})
					}

					let obj = {
						isLogout: this.state.isLogout,
						allTabAction: "logoutAllTab"
					}

					channel.postMessage(obj);
				})
			}, 1000)

		})
	}

	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
	};


	componentDidMount = () => {
		const { userEntryType, logout } = this.props;
		// Check if org_name is not present
		if (!userEntryType || !userEntryType.hasOwnProperty('org_name')) {
			// Trigger logout action
			logout();
		}
		document.body.addEventListener('mousedown', this.handleClickOutside.bind(this));
	}

	componentWillUnmount() {
		document.body.removeEventListener('mousedown', this.handleClickOutside.bind(this));
	}





	handleClickOutside(event) {
		const concernedElement = document.querySelector(".popover_notificationParrent");
		if (concernedElement && concernedElement.contains(event.target)) {
		} else {
			this.setState({
				showPopup: false,
				offset: 0,
				limit: 5
			})
		}
	}

	//notification header ui
	notificationsUi = () => {
		const { notificationsData } = this.state;
		let notiArry = []
		if (notificationsData.length > 0) {
			notificationsData.map((value, idx) => {
				if (value.is_read) {
					notiArry.push(
						<div className="popover_notification_row notidivider isread" key={idx} >
							<div className="noti_icon_box">
								<i className="fa fa-bell" aria-hidden="true"></i>
							</div>
							<div className="noti_user_box">
								<h5>{value.subject}</h5>
								<p>{value.body}</p>
							</div>
							<div className='seenUnseenBox'>
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Unseen</Tooltip>}>
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-check-circle unseen" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
										<path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
									</svg>
								</OverlayTrigger>
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Seen</Tooltip>}>
									<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-eye seen" viewBox="0 0 16 16">
										<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
										<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
									</svg>
								</OverlayTrigger>
							</div>
						</div>
					)
				} else {
					notiArry.push(
						<div className="popover_notification_row notidivider notread" key={idx} onClick={() => this.notificationsRead(value.id)}>
							<div className="noti_icon_box">
								<i className="fa fa-bell" aria-hidden="true"></i>
							</div>
							<div className="noti_user_box">
								<h5>{value.subject}</h5>
								<p>{value.body}</p>
							</div>
							<div className='seenUnseenBox'>
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Unseen</Tooltip>}>
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-check-circle unseen" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
										<path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
									</svg>
								</OverlayTrigger>
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Seen</Tooltip>}>
									<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-eye seen" viewBox="0 0 16 16">
										<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
										<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
									</svg>
								</OverlayTrigger>
							</div>
						</div>
					)
				}
			})
		}

		return notiArry;
	}

	//load more notification
	loadMore = () => {
		const { limit, offset } = this.state;
		this.setState({
			offset: offset + limit
		}, () => {
			this.userNotifications().then((res) => {
				if (res.data.length > 0) {
					this.setState({
						notificationsData: [...this.state.notificationsData, ...res.data],
						unreadNotificationCount: [...this.state.unreadNotificationCount, ...res.unread_message_count]
					}, () => {
						this.notificationsUi();
					})
				} else {
					this.setState({
						showLoadmore: false
					})
				}
			})
		})

	}

	//notification get api
	userNotifications = () => {
		let promise = new Promise((resolve, reject) => {
			const { loaderStateTrue, loaderStateFalse, userCredentials } = this.props;
			const { limit, offset } = this.state;
			let param = {}
			param = {
				"limit": limit,
				"offset": offset,
			};

			let filters = {};
			filters['filters'] = JSON.stringify(param);

			loaderStateTrue()
			UtilityController.notifications(filters).then((res) => {
				if (res.success) {
					this.setState({
						showLoadmore: res.total > 5 ? true : false,
					})
					resolve(res)

				}

				loaderStateFalse()
			}).catch((error) => {
				console.error("************error*************", error)
				if (error) {
				}

				if (error.message == "Network Error") {

				}
				loaderStateFalse()
			});
		})
		return promise

	}

	notificationsRead = (id) => {
		const { loaderStateTrue, loaderStateFalse } = this.props;
		let data = {}
		data = {
			"is_read": true,
			"id": id,
		};
		loaderStateTrue();
		UtilityController.notificationsUserId(data).then((response) => {
			if (response[0].success) {
				this.notificationListFn()
			}
			loaderStateFalse();
		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
			if (error.message == "Network Error") {
			}
		});
	}



	componentWillUnmount() {
	}


	handleOutsideClickMenuleft(e) {
		if (e.target.offsetParent) {
			if (e.target.localName != "aside") {
				if (e.target.className != "nav-link") {
					this.props.handleLeft(false);
				}
			}
		} else {

			if (e.target.parentNode.className != "nav-link") {
				if (e.target.parentNode.className.animVal != "float-right") {
				}
			}
			if (e.target.className.animVal == "fa-bars-menu") {
				this.props.handleLeft(false);
			}
			if (e.target.parentNode.className.animVal == "fa-bars-menu") {
				this.props.handleLeft(false);
			}
		}

	}
	handleBar = () => {
		this.props.handleLeft(!this.props.leftbar)
	}

	lanOnSelect = (lng) => {
		this.setState({
			langaugeConfirmModal: true,
			selectedLangauge: lng,

		})
	}


	confirmLangaugeChange = () => {
		const { selectedLangauge } = this.state;
		this.props.i18n.changeLanguage(selectedLangauge);
		let splitUrl = this.props.match.url.split('/');
		this.props.history.push(`/${selectedLangauge}/${splitUrl[2]}`);
		window.location.reload();
		this.setState({
			langaugeConfirmModal: false
		})

	}

	cancelLangaugeChange = () => {
		this.setState({
			langaugeConfirmModal: false,
		})
	}

	popover = () => (
		<Popover className="popover_notification">
			<Popover.Content>

				<div className="popover_notification_row">
					<h3 className="notititle">{this.props.t('notifications')}</h3>
				</div>

				<div>
					{this.notificationsUi()}
					{this.state.showLoadmore ?
						<div onClick={this.loadMore}>{this.props.t('loadMore')}</div>
						: null}
				</div>


			</Popover.Content>
		</Popover>
	);


	showPopup = () => {
		this.setState({
			showPopup: true
		})
	}
	notificationListFn = () => {
		this.userNotifications().then((res) => {
			this.setState({
				notificationsData: res.data,
				unreadNotificationCount: res.unread_message_count,
				offset: 0,
				limit: 5
			}, () => {
				this.notificationsUi();
			})
		})
	}


	changePassword = () => {
		this.setState({
			changePasswordFlag: true
		})
	}

	closeChangePassword = () => {
		this.setState({
			changePasswordFlag: false,
			zoominzoomoutflag: ""
		})
	}


	handleVersionChange = (event) => {
		let copyObject = Object.assign({}, this.props.userCredentials);
		copyObject["user_details"]["version"] = event.label.toLowerCase();
		copyObject["user_details"]["version_id"] = event.value;
		this.props.setUserCredentials(copyObject);
	}


	//alert notification
	handleCrossButtonClick = (id) => {
		this.setState((prevState) => ({
			notificationData: prevState.notificationData.filter((error) => error.id !== id)
		}));
	};

	showNotificationGetApiCall = () => {
		let filters = {};
		let globalQueryParamshash = {};
		globalQueryParamshash["status"] = true;
		filters['filters'] = globalQueryParamshash;
		this.props.loaderStateTrue();
		UtilityController.showNotification(filters).then((response) => {
			if (response.success) {
				if (response.data != null && response.data.length > 0) {
					let responseDataTempArray = [];
					let responseDataTempObj = {};
					response.data.map((e, index) => {
						responseDataTempObj = {};
						responseDataTempObj["message"] = e.message;
						responseDataTempObj["id"] = index;
						responseDataTempArray.push(responseDataTempObj)
					})
					this.setState({
						notificationData: responseDataTempArray
					})

				} else {
					this.setState({
						notificationData: []
					})
				}

			} else {
				Utility.toastNotifications("Something went wrong !", "Error", "error")
			}
			this.props.loaderStateFalse();
		}).catch((error) => {
			this.props.loaderStateFalse();
		});
	}

	zoominFunction = () => {
		let zoominzoomoutflag = "zoominzoomoutclass"
		this.setState({
			zoominzoomoutflag: zoominzoomoutflag
		})
	}
	zoomoutFunction = () => {
		let zoominzoomoutflag = ""
		this.setState({
			zoominzoomoutflag: zoominzoomoutflag
		})
	}

	formatString = (inputString) => {
		const words = inputString.split('_');
		const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
		const formattedString = formattedWords.join('');
		return formattedString;
	}

	//profile modal open
	myProfileFN = () => {
		this.setState({
			myProfileModalFlag: true
		})
	}

	//close my profile modal
	closemyProfileModal = () => {
		let zoominzoomoutflag = ""
		this.setState({
			myProfileModalFlag: false,
			zoominzoomoutflag: zoominzoomoutflag
		})
	}

	detectChangesIndividualTab = (value = false) => {
		this.setState({
			tabchangeParent: value
		})

	}

	//current user data get
	getCurrentUserData = () => {
		const { setUserCredentials } = this.props;
		getCurrentUser().then((response) => {
			if (response.success) {
				setUserCredentials(response.data);
				this.props.history.push('/');
			}
		}).catch((error) => {
			console.error("************error*************", error)
			loaderStateFalse();
		});
	}

	render() {
		const { userCredentials } = this.props;
		const { subheaderShow, windowWidth, changePasswordModalOpen, formData, errorData } = this.state;
		let url = window.location.href;
		let currentRouteName = url.split("/").pop();
		let user_email = "";
		if (userCredentials && userCredentials.user_details && userCredentials.user_details.user_email != "") {
			user_email = userCredentials.user_details.user_email
		}
		if (user_email == "" && userCredentials && userCredentials.user_details && userCredentials.user_details.contact_number != "") {
			user_email = userCredentials.user_details.contact_number
		}
		let user_profile_image = "";
		if (userCredentials && userCredentials.user_details && userCredentials.user_details.hasOwnProperty('profile_img_url')) {
			let parseObject = userCredentials.user_details.profile_img_url ? JSON.parse(userCredentials.user_details.profile_img_url) : "";
			if (parseObject != "" && parseObject.hasOwnProperty('img_url') && parseObject.img_url != "") {
				user_profile_image = parseObject.img_url
			}
		}
		let user_first_name = userCredentials && userCredentials.user_details && userCredentials.user_details.first_name
		let user_last_name = userCredentials && userCredentials.user_details && userCredentials.user_details.last_name
		let roleName = this.props.userCredentials?.user_details?.role_name
		let userId = this.props.userCredentials?.id
		var rowData = {
			"user_type": roleName
		}
		let userType = {
			label: `${this.props.userEntryType && this.props.userEntryType.hasOwnProperty('org_name') && this.props.userEntryType.org_name && this.props.userEntryType.org_name.length > 0 ? `${this.props.userEntryType.org_name}` : ''}`,
			value: this.props.userEntryType.id,
			tooltipShow: `${this.props.userEntryType && this.props.userEntryType.hasOwnProperty('org_name') && this.props.userEntryType.org_name}`.length > 20 ? true : false
		};

		return (
			<div className="header-top-inner">
				<header className={roleName != "app_admin" && this.state.notificationData.length > 0 ? "header_main header_main_index" : "header_main"} >
					{currentRouteName == "dashboard" ?
						<div className='pageTitle'>{`${this.formatString(roleName)} Dashboard`.length >= 16 ? <OverlayTrigger overlay={<Tooltip>{`${this.formatString(roleName)} Dashboard`}</Tooltip>} placement='bottom'>
							<div className='chartCountBox'>
								{(`${this.formatString(roleName)} Dashboard`).substring(0, 13) + "..."}
							</div>
						</OverlayTrigger> : `${this.formatString(roleName)} Dashboard`}</div>
						: null}
					{currentRouteName == "agent_dashboard" ?
						<div className='pageTitle'>{`${this.formatString(roleName)} Dashboard`.length >= 16 ? <OverlayTrigger overlay={<Tooltip>{`${this.formatString(roleName)} Dashboard`}</Tooltip>} placement='bottom'>
							<div className='chartCountBox'>
								{(`${this.formatString(roleName)} Dashboard`).substring(0, 13) + "..."}
							</div>
						</OverlayTrigger> : `${this.formatString(roleName)} Dashboard`}</div>
						: null}
					<div className="header_right user_icon_innner" >
						<ul className="nav_ul">
							{/* <OverlayTrigger
								rootClose={true}
								trigger="click"
								placement="bottom"
								show={this.state.showPopup}
								overlay={
									<Popover className="popover_notification">
										<Popover.Body>
											<div className="popover_notification_row">
												<h3 className="notititle">{this.props.t('notifications')}</h3>
											</div>
											<div className="popover_notificationParrent" ref={this.codeOutsideClickRef}>
												<div className='notificationsUiBox'>{this.notificationsUi()}</div>
												{this.state.showLoadmore ?
													<div onClick={this.loadMore} className="savebtn">{this.props.t('loadMore')}</div>
													: null}
											</div>
										</Popover.Body>
									</Popover>
								}

							>
								<li className={this.state.notificationsData.length > 0 ? "notificationbar animationShow" : "notificationbar"} onClick={this.showPopup}><i className="fa fa-bell" aria-hidden="true"></i>
									{this.state.unreadNotificationCount > 0 ?
										<sup>5</sup>
										: <sup>0</sup>}
								</li>

							</OverlayTrigger> */}

							{roleName == "app_admin" ?
								<li style={{ display: 'none' }}>
									<div className="dropdownbox versiondrop">
										<AutosuggestComponent
											handleOnChange={(e) => { this.handleVersionChange(e) }}
											name=''
											isMulti={false}
											placeholder="Choose version"
											isDisabled={false}
											closeButton={false}
											menuHeader="Choose version"
											isSearchable={true}
										/>
									</div>
								</li> : null}

							{this.props.hasOwnProperty("userGroup") && this.props.userGroup && this.props.userGroup.length > 1 ?
								<div className="switchOrganizationBox">
									<label>Switch Business Type : </label>
									<div className="dropdownbox versiondrop dropdownCustomize">
										<AutosuggestComponent
											handleOnChange={(e) => {
												this.props.setUserEntryType(e);// User entry type
												setTimeout(() => {
													this.getCurrentUserData();
												}, 100);
											}}
											options={this.props.userGroup.map((role, idx) => ({
												...role,
												label: `${this.props.userGroup?.[idx]?.org_name}`,
												value: this.props.userGroup?.[idx].id,
												tooltipShow: `${this.props.userGroup?.[idx]?.org_name}`.length > 20 ? true : false
											}))}
											selectedValue={userType}
											name=''
											isMulti={false}
											placeholder="Choose role..."
											isDisabled={false}
											closeButton={false}
											menuHeader="Choose role"
											isSearchable={true}
										/>
									</div>
								</div>
								: null}
							{user_profile_image != "" ?
								<li>
									<OverlayTrigger placement="bottom" overlay={<Tooltip>{`${user_first_name} ${user_last_name}`}</Tooltip>}>
										<img className="user_icon" src={user_profile_image} />
									</OverlayTrigger>
								</li>
								:
								<li>
									<OverlayTrigger placement="bottom" overlay={<Tooltip>{`${user_first_name} ${user_last_name}`}</Tooltip>}>
										<div className="header_user_name">
											{user_first_name && user_first_name.charAt(0)}{user_last_name && user_last_name.charAt(0)}
										</div>
									</OverlayTrigger>
								</li>
							}
							<OverlayTrigger
								rootClose={true}
								trigger="click"
								placement="bottom"
								overlay={
									<Popover className="popover_action_inner">
										<Popover.Body>
											<div className="popover_action_row">
												<button className="mailtext" onClick={this.myProfileFN}>{this.props.t('My Profile')}</button>
												{roleName != "app_admin" &&
													<button className="changePasswordtext" onClick={this.changePassword}>{this.props.t('ChangePasswordButton')}</button>}

												<button className="logouttext" onClick={this.logout}>{this.props.t('logoff')}</button>
											</div>
										</Popover.Body>
									</Popover>
								}
							>
								<li className="logoutbox"><i className="fa fa-sort-desc" aria-hidden="true"></i></li>
							</OverlayTrigger>

						</ul>
					</div>
					<ModalGlobal
						show={this.state.langaugeConfirmModal}
						onHide={this.cancelLangaugeChange}
						className="modalcustomize confirmationalertmodal"
						bodyClassName="cancelConfirmationbody"
						headerclassName="close_btn_icon"
						title={this.props.t('languagechange')}
						footer={false}
						body={
							<ConfirmationAlert
								BodyFirstContent={this.props.t('areYouSureYouWantToReload')}
								confirmationButtonContent={this.props.t('confirm')}
								deleteConfirmButton={this.confirmLangaugeChange}
								cancelButtonContent={this.props.t('cancel')}
								deleteCancleButton={this.cancelLangaugeChange}
							/>
						}
					/>

					<ModalGlobal
						show={this.state.changePasswordFlag}
						onHide={this.closeChangePassword}
						className={`${this.state.zoominzoomoutflag + ' ' + "modalcustomize mondimension changePasswordModal"}`}
						bodyClassName="cancelConfirmationbody"
						headerclassName="close_btn_icon"
						title={this.props.t('ChangePasswordButton')}
						footer={false}
						closeButton={true}
						body={
							<ChangePasswordContent
								closeChangePassword={this.closeChangePassword}
							/>
						}
					/>
					<ModalGlobal
						show={this.state.myProfileModalFlag}
						onHide={this.closemyProfileModal}
						className={`${this.state.zoominzoomoutflag + ' ' + "modalcustomize mondimension myProfileModal"}`}
						bodyClassName="profileDetailsModalbody"
						headerclassName="close_btn_icon"
						saveButtonLabel="Submit"
						saveButtonClassName="btn btn-primary savebtn modbtn"
						title={this.props.t('My Profile')}
						footer={false}
						closeButton={true}
						zoominButton={this.state.zoominzoomoutflag != "" ? false : true}
						zoomoutButton={this.state.zoominzoomoutflag != "" ? true : false}
						zoomin={this.zoominFunction}
						zoomout={this.zoomoutFunction}
						cancelButtonClassName="modbtn cancelbtn"
						footerClassName="text-center topspace"
						body={

							<UserProfile
								profilecontentdispaly="profilecontentdispaly"
								userProfileId={userId}
								detectChangesIndividualTab={this.detectChangesIndividualTab}
								selectedRowsData={rowData}
								profileView={true}
							/>
						}
					/>
				</header >
			</div>
		)
	}
}

const mapStateToProps = (globalState) => {
	return {
		userCredentials: globalState.LoginReducer.userCredentials,
		accessToken: globalState.LoginReducer.accessToken,
		token: globalState.LoginReducer.token,
		userGroup: globalState.LoginReducer.userGroup,
		organisationGroup: globalState.LoginReducer.organisationGroup,
		userEntryType: globalState.LoginReducer.userEntryType,
		leftbar: globalState.mainReducerData.leftbar,
	};
}
export default connect(mapStateToProps, { handleLeft, loaderStateTrue, loaderStateFalse, setToken, setUserEntryType, setUserCredentials, logOutApp })(withNamespaces()(withRouter(Header)));