import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import CustomButton from '../../../Utility/Components/CustomButton'
import Cookies from 'js-cookie';

function VariantsExample(props) {

    return (
        <>
            {['Primary'].map(
                (variant) => (
                    <DropdownButton
                        //as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={localStorage.getItem('i18nextLng') != null ? localStorage.getItem('i18nextLng').toUpperCase() : 'EN'}
                        onSelect={props.lanOnSelect.bind(this)}
                    >
                        <Dropdown.Item eventKey="en">EN</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="fr">FR</Dropdown.Item>

                    </DropdownButton>
                ),
            )}
        </>
    );
}

class CommonLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { pageBackground, appContainerInner, loginBoxInner, logoBoxInner, companyLogo, titleLabel, webTitle, loginFormDiv, rememberForgotInner, rememberTitle, forgotTitle, submitTitle, checkboxContainer, checkmark, loginBtn, checkboxType, buttonType, parentClassName, checkboxName, checkboxValue, passInputClass, passLockIcon, passEyeIcon, errorClass, passPlaceholder, passName, lockClass, eyeClass, eyeSlash, showPassword, showText, passwordLock } = this.props;
        return (
            <div className={pageBackground}>
                <div className={appContainerInner}>
                    <div className={loginBoxInner}>
                        <div className={logoBoxInner}>
                            <div className="titleposition">
                                <img src={require('../../../Utility/Public/images/stayingLogo.png')} className="logoInner" />
                            </div>
                        </div>
                        <form className={loginFormDiv}>
                            {/* login email field */}
                            <div className={parentClassName}>
                                <input type="text" onChange={this.props.handleChange} name="email" value={this.props.email} placeholder="Email" className={passInputClass} id="login_useremail" onFocus={this.props.handleonFocus} onBlur={this.props.handleonBlur} onKeyDown={this.props.handleKeyPress} />
                                <div className={errorClass}>{this.props.emailError}</div>
                            </div>
                            {/* login password field */}
                            <div className={parentClassName}>
                                <input type={this.props.passtype == "text" ? "text" : "password"} onChange={this.props.handleChange} name={passName} value={this.props.password} placeholder="Password" className={passInputClass} id='login_userpassword' onFocus={this.props.handleonFocus} onBlur={this.props.handleonBlur} onKeyDown={this.props.handleKeyPress} />
                                {!passwordLock ?
                                    <span className={passEyeIcon} onClick={showText}>
                                        <i className={eyeSlash} aria-hidden="true"></i>
                                    </span>
                                    :
                                    <span className={passEyeIcon} onClick={showPassword}>
                                        <i className={eyeClass} aria-hidden="true"></i>
                                    </span>
                                }
                                <div className={errorClass}>{this.props.passwordError}</div>
                            </div>
                            {/* remember me field */}
                            <div className='rememberMe'>
                                <label className="remembercheckboxcontainer">Remember me
                                    <input type="checkbox" checked={this.props.isRemember} onChange={(e) => this.props.rememberMe(e)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            {/* login button */}
                            <div className="loginFormInnerDiv logbtndiv">
                                <CustomButton
                                    onClick={this.props.login}
                                    className="login-btn btn btn-primary"
                                    children='Login'
                                    type="button"
                                />
                            </div>
                        </form>
                        {/* forget password  */}
                        <div className={rememberForgotInner}>
                            <p onClick={this.props.forgotPassword}><span>Forgot your password ?</span></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


CommonLogin.defaultProps = {
    email: "",
    password: "",
    pageBackground: 'loginBackground',
    appContainerInner: 'appContainerInner',
    loginBoxInner: 'login_box_inner',
    logoBoxInner: 'logo-box-inner',
    companyLogo: 'logo-box-top-logo',
    titleLabel: 'loginTitle',
    loginFormDiv: 'loginFormDiv',
    rememberForgotInner: 'loginFormInnerDiv forgot_password_property',
    checkboxContainer: 'custom_checkbox_container_log',
    checkmark: 'checkmarkCheckbox',
    loginBtn: 'login-btn',
    parentClassName: 'loginFormInnerDiv',
    passInputClass: 'input__fields_property',
    passLockIcon: 'passwordLockIcon',
    passEyeIcon: 'passwordEyeIcon',
    errorClass: 'col-md-12 errorClass error_div',
    passName: 'password',
    lockClass: 'fa fa-lock',
    eyeSlash: 'fa fa-eye-slash',
    eyeClass: 'fa fa-eye',

    checkboxType: 'checkbox',
    checkboxName: 'remember_me',
    checkboxValue: '',
    buttonType: 'button',

    passPlaceholder: 'Mot de passe',
    webTitle: 'HR Management System',
    rememberTitle: 'Remember Me',
    forgotTitle: 'Mot de passe oublié?',
    submitTitle: 'Se connecter'
}
export default CommonLogin;
