import React, { Component, useEffect, lazy, Suspense } from 'react';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink, connectToWebsocket, disconnectToWebsocket } from '../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../Login/Actions/LoginAction';
import { withRouter } from 'react-router-dom';
import * as Store from '../../store'
const version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? (Store.default.getState().LoginReducer.userCredentials.user_details.version).toUpperCase() == "" ? "V4" : (Store.default.getState().LoginReducer.userCredentials.user_details.version).toUpperCase() : "V4";
const HomePage = React.lazy(() => import(`../../${version}/Modules/Home/Pages/HomePage`));
const SettingsPage = React.lazy(() => import(`../../${version}/Modules/Settings/Pages/SettingsPage`));
import Auth from '../../Layout/Auth';
import LoadingOverlay from 'react-loading-overlay';
import LoginRoute from '../Login/Routes/LoginRoute';

function GlobalRoute(props) {
    const history = useHistory();
    return (
        <Switch>
            {/* Home module route */}
            <Route
                exact
                path="/:lng/home/:type?/:branchId?"
                render={() => (
                    <Auth history={history} allowedRoles={['app_admin', 'admin', 'user', 'staff']}>
                        <Suspense fallback={<Loading />}>
                            <HomePage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />

            {/* setting module route */}
            <Route
                exact
                path="/:lng/settings/:type?/:next_step?"
                render={() => (
                    <Auth history={history} allowedRoles={['app_admin', 'admin', 'user', 'staff']}>
                        <Suspense fallback={<Loading />}>
                            <SettingsPage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />

            {/* login module route */}
            <Route path="/" component={LoginRoute} />
        </Switch>
    );

}

function Loading() {
    return (
        <>
            <div className="wrapper">
                <LoadingOverlay
                    active={true}
                    className='lodingOverlayDiv'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(255, 255, 255, 0.1)',
                            zIndex: '9999999999999'
                        })
                    }}
                ></LoadingOverlay>
            </div>
        </>
    );
}


const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
    };
}


export default connect(mapStateToProps, { handleActiveLink, setUserCredentials })(withRouter(GlobalRoute));
